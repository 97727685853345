  export const environment = {
    production: false,
    apiUrl: process.env.REACT_APP_APPURL,
    public_key: process.env.REACT_APP_PUBLIC_KEY,
    timeOut: process.env.REACT_APP_TIMEOUT,
    showCreateAdhoc: process.env.REACT_APP_ADHOC,
    gov: true,
    facility: "Task Order #",
    dept: "Location",
    appVersion: "5.1.21",
    appID: "1"
  };


// export const environment = {
//     production: false,
//     apiUrl: "http://localhost:8081/shiftrock",
//     //apiUrl: "https://test.hwlmsp.com/shiftrock",
//     public_key: "6Lf6neQSAAAAACpLTNsvIJmojMsccYWoVY4fTC1A",
//     timeOut: "30000",
//     showCreateAdhoc: true
//   };

